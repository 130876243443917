import { Container } from 'Atoms/Container';
import { AnimatePage } from 'Atoms/AnimatePage';
import Typed from 'react-typed';
import { SeoHead } from 'Atoms/SeoHead';

const IndexPage = () => {
	const talkAbout = [
		'Python',
		'Django',
		'TypeScript',
		'React',
		'Next.js',
		'RESTful APIs',
		'GraphQL',
		'Hasura',
		'Django REST Framework',
		'Git',
		'PostgreSQL',
		'Docker',
		'Kubernetes',
		'Cloud Platforms',
		'DevOps',
		'Data Structures and Algorithms',
		'System Design',
		'Design Patterns',
		'Software Testing Methodologies',
		'Data Analytics',
		'Machine Learning',
	];

	return (
		<AnimatePage>
			<SeoHead
				title="Deepankar Sharma – Senior Software Engineer"
				description="I&apos;m a Senior Software Engineer from India, specialized in backend development with Python and Node.js."
				keywords={[
					'Senior Software Engineer',
					'Full Stack Engineer',
					'Backend Engineer',
					'Deepankar',
					'Python',
					'Pythonista',
					'Django',
					'Next.js',
					'React',
					'TypeScript',
					'GraphQL',
					'Hasura',
					'Senior',
					'Developer',
					'Engineer',
				]}
			/>
			<Container>
				<h1 className="headline mt-20 text-3xl md:text-5xl lg:text-6xl">
					Hey, I&apos;m Deepankar Sharma 👋
				</h1>
				<p className="my-8 text-lg">
					I&apos;m a Software Engineer from India, specialized in backend development with Python and Node.js.
					As an advocate for web performance and accessibility, I create amazing web applications to make the internet
					a better and more secure place. You can talk to me about {' '}
					<Typed
						typeSpeed={40}
						loop
						backSpeed={20}
						strings={talkAbout}
						smartBackspace
						backDelay={1000}
						loopCount={0}
						showCursor
						cursorChar="|"
						className="headline font-normal"
					/>
					.
				</p>
			</Container>
		</AnimatePage>
	);
};

export default IndexPage;
