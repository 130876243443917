import Head from 'next/head';
import format from 'date-fns/format';

export interface HeadProps {
	title: string;
	description: string;
	lang?: string;
	keywords?: string[];
	author?: string;
	image?: string;
	type?: string;
	date?: string;
	modifiedDate?: string;
	canonicalUrl?: string;
	meta?: Array<{
		name: string;
		content: string;
	}>;
	article?: {
		publishedTime: string;
		modifiedTime: string;
		expirationTime?: string;
		authors?: string[];
		section?: string;
		tags?: string[];
	};
}

const SeoHead = ({
	title = 'Deepankar Sharma - Senior Software Engineer',
	description,
	author = 'Deepankar Sharma',
	keywords,
	image = 'https://www.deepankar.online/images/deepankar.online.png',
	type = 'website',
	date,
	modifiedDate,
	canonicalUrl,
	meta = [],
	article,
}: HeadProps) => {
	return (
		<Head>
			{/* Basic Meta Tags */}
			{article?.modifiedTime ? (
				<title>{`${title} | Deepankar's Blog | ${format(new Date(article.modifiedTime), 'MMM yyyy')}`}</title>
			) : (
				<title>{`${title}`}</title>
			)}

			<meta name="description" content={description} />
			<meta name="author" content={author} />
			{keywords && <meta name="keywords" content={keywords.join(', ')} />}

			{/* Open Graph */}
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:type" content={type} />
			<meta property="og:site_name" content="Deepankar Sharma's Blog" />
			{canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
			{image && <meta property="og:image" content={image} />}
			{image && <meta property="og:image:alt" content={`Cover image for ${title}`} />}

			{/* Twitter Card */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="https://x.com/geeky_lad" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:creator" content={author} />
			{image && <meta name="twitter:image" content={image} />}

			{/* Article Specific Meta Tags */}
			{article && (
				<>
					<meta property="article:published_time" content={article.publishedTime} />
					{article.modifiedTime && (
						<meta property="article:modified_time" content={article.modifiedTime} />
					)}
					{article.section && (
						<meta property="article:section" content={article.section} />
					)}
					{article.tags?.map((tag) => (
						<meta property="article:tag" content={tag} key={tag} />
					))}
				</>
			)}

			{/* Additional Meta Tags */}
			{meta.map(({ name, content }) => (
				<meta name={name} content={content} key={name} />
			))}

			{/* Technical Meta Tags */}
			<meta charSet="utf-8" />
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			{canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

			{/* RSS Feed */}
			<link
				rel="alternate"
				type="application/rss+xml"
				title={`${author}'s Blog RSS Feed`}
				href="/rss.xml"
			/>
		</Head>
	);
};
export { SeoHead };
